import * as React from 'react'
import { Helmet } from 'react-helmet'
import DefaultOgImage from '../images/og-image.png'

const SITE_NAME = process.env.GATSBY_SITE_NAME
const SITE_TITLE = process.env.GATSBY_SITE_TITLE

type BlogPostSchema = {
  '@context': 'https://schema.org'
  '@type': 'BlogPosting'
  'mainEntityOfPage': {
    '@type': 'WebPage'
    '@id': string
  }
  'headline': string
  'description': string
  'image': string
  'author': {
    '@type': 'Person'
    'name': string
  }
  'datePublished': string
}

type FaqSchema = {
  '@context': 'https://schema.org'
  '@type': 'FAQPage'
  'mainEntity': {
    '@type': 'Question'
    'name': string
    'acceptedAnswer': {
      '@type': string
      'text': string
    }
  }[]
}

type MetaProps = JSX.IntrinsicElements['meta']
type LinkProps = JSX.IntrinsicElements['link']

type SeoHeadersProps = {
  title?: string
  description?: string
  ogType?: string
  ogImage?: string
  meta?: MetaProps[]
  link?: LinkProps[]
  schemaMarkup?: BlogPostSchema | FaqSchema
}

export const SeoHeaders = ({
  title,
  description,
  ogType,
  ogImage,
  meta = [],
  link = [],
  schemaMarkup,
}: SeoHeadersProps) => (
  <Helmet
    htmlAttributes={{
      lang: 'es',
    }}
    titleTemplate={`${SITE_NAME} - %s`}
    defaultTitle={`${SITE_NAME} - ${SITE_TITLE}`}
    title={title}
    meta={[
      ...(description ? [{ name: 'description', content: description }] : []),
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:type',
        content: ogType || 'website',
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        property: 'og:image',
        content: ogImage || DefaultOgImage,
      },
      ...meta,
    ]}
    link={[
      ...link,
    ]}
  >
    {schemaMarkup &&
      <script type='application/ld+json'>{JSON.stringify(schemaMarkup)}</script>
    }
  </Helmet>
)

export default SeoHeaders
