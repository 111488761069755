import * as React from 'react'
import { Script } from 'gatsby'
import SeoHeaders from '../components/seo_headers'

const SignUp = () => (
  <React.Fragment>
    <SeoHeaders description='Crea tu cuenta en Netastract' />
    <div
      data-tf-widget='kS70Eo2X'
      data-tf-opacity='100'
      data-tf-inline-on-mobile
      data-tf-iframe-props='title=Netastract'
      data-tf-transitive-search-params
      data-tf-auto-focus
      data-tf-medium='snippet'
      data-tf-full-screen
    ></div>
    <Script src='//embed.typeform.com/next/embed.js' />
  </React.Fragment>
)

export default SignUp
